// Get references to the link and the 'bank-logos' div
const bankToggleLink = document.getElementById('bankToggleLink');
const bankLogosDiv = document.getElementById('bank-logos');

// Add a click event listener to the link
bankToggleLink.addEventListener('click', function (event) {
    event.preventDefault(); // Prevent the default link behavior (e.g., navigating to the href)

    // Toggle the visibility of the 'bank-logos' div
    if (bankLogosDiv.style.display === 'none') {
        bankLogosDiv.style.display = 'grid';
    } else {
        bankLogosDiv.style.display = 'none';
    }
});
